import React, { useState } from "react";
import axios from "axios";
import {FormattedMessage} from "react-intl";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import { Link, useParams } from "react-router-dom";
import { useEffect, componentWillMount } from "react";
import moment from 'moment'

const BookingsDetails = () => {
  const [spaceDetail, setDetail] = useState(null);
  const [currentBooking, setBooking] = useState(null);
  const [network, setNetwork] = useState(null);
  const [nameClass, setClass] = useState("d-block");
  const { id } = useParams();
  const { space_id } = useParams();

  function getReservationStateText(booking)
  {
    if (booking?.reservation_status  == "1")
      return "Abgeschlossen";
  if (booking?.reservation_status  == "2")
      return "Reservierung bestätigt";
  if (booking?.reservation_status  == "3")
      return "Reservierung wird durchgeführt";
  if (booking?.reservation_status  == "4")
      return "Warte auf Bestätigung"
  if (booking?.reservation_status  == "5")
      return "Done";
  if (booking?.reservation_status  == "6")
      return "Maintenance";
  if (booking?.reservation_status  == "7")
      return "No Show";
  if (booking?.reservation_status  == "8")
      return "Reservierung abgelehnt";
  }

  function getFormattedCheckinDate(booking)
  {
    var checkin = booking?.reservation?.date_checkin;
    return new Date(checkin).toLocaleDateString('de-DE');
  }

  function getFormattedTime(booking)
  {
    var checkin = booking?.reservation?.date_checkin;
    var checkout = booking?.reservation?.date_checkout; 
    return new Date(checkin).toLocaleTimeString('de-DE') +" - " + new Date(checkout).toLocaleTimeString('de-DE');
  }

  useEffect(() => {
    axios
    .get(
      `https://api.avegoo.com/protected/app/space/${space_id}/network/`)
    .then((res) => setNetwork(res.data[0]))
    .catch((err) => console.log(err));
    }, []); 

  useEffect(() => {
    axios
      .get(
        `https://api.avegoo.com/protected/app/user/booking/reservation/list`)
      .then((res) => setBooking(res.data.bookings.find(e => e.id == id)))
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    axios
    .get(`https://api.avegoo.com/protected/app/space/${space_id}/detail/`)
    .then((res) => setDetail(res.data[0]))
    .catch((err) => console.log(err));
  }, []);

        return (
          <div className={` ${nameClass}`}>
          <CardMetronic className="container p-0">
              <CardBodyMetronic>
                <div>
                  <ul className="nav flex-column" style={{gap: "1.4rem"}}>
                    <li>
                      <div>
                        <div className="co-work-heading">
                          <div className="font-weight-bold text-dark-50 mb-4">
                            Buchungsdetails
                          </div>
                          <h1 className="mb-4 font-weight-boldest">
                            {spaceDetail?.title}
                          </h1>
                          <h3 className="font-weight-boldest">Deine Reservierungsdetails</h3>
                          <div className="pl-5">
                          <h6 className="mb-0 font-weight-bold">
                              {getFormattedCheckinDate(currentBooking)}<br/> 
                              {getFormattedTime(currentBooking)}
                              <br/> {getReservationStateText(currentBooking)}
                            </h6>
                            </div>
                        </div>
                      </div>
                    </li>
            
                    <li>
                      <div>
                        <h3 className="mb-5 font-weight-boldest">Ort</h3>
                        <div className="pl-5">
                          <h6 className="mb-5 font-weight-bold">
                            {spaceDetail?.addressline1}
                            <br/> {spaceDetail?.city}, {spaceDetail?.country}
                          </h6>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div>
                        <h3 className="mb-5 font-weight-boldest">Dein WiFi Zugang</h3>
                        <div className="pl-5">
                          <h6 className="mb-5 text-capitalize font-weight-bold">
                            Name: {network?.ssid}
                            <br/> 
                            Passwort: {network?.key}
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </CardBodyMetronic>
              <div className="card-footer">
                <div className="d-flex justify-content-between">
                  <Link
                      to="/bookings"
                      className="btn btn-lg text-success bg-success-o-35 hover-opacity-79 text-capitalize"
                  >
                    <FormattedMessage id="FORM.GENERAL.BACK_BUTTON"/>
                  </Link>
                 
                </div>
              </div>
          </CardMetronic>
        </div>
        );
};

export default BookingsDetails;
