import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CardDeck } from "react-bootstrap";
import { Avatar, Card, CardHeader, CardContent } from "@material-ui/core";

import { Grid, makeStyles } from "@material-ui/core";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    margin: 8,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  avatar: {
    backgroundColor: "#9AD9A3",
  },
  addIcon: {
    fontSize: 220,
    color: "#A1A5A6",
  },
  addCardContent: {
    margin: 8,
    display: "flex",
    justifyContent: "center",
  },
  addCard: {
    display: "grid",
  },
  delete: {
    marginLeft: "auto",
  },
  loadingCard: {
    height: "50%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Overview() {
  const authToken = useSelector((state) => state.auth.authToken);
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;
  const [spaces, setSpaces] = useState([]);
  const classes = useStyles();

  const cardsList = [{}, {}, {}, {}, {}, {}];

  useEffect(() => {
    axios
      .get(
        `https://api.avegoo.com/protected/app/space/nearby?lat=48.264605&long=8.849598`)
      .then((res) => setSpaces(res.data))
      .catch((err) => console.log(err));
  }, []);

  function getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstLetters;
  }

  function getThumbnail(params) {
    if(params === 616)
    {
      return "/media/bg/xeomed_flex.jpeg";
    }

    if(params === 581)
    {
      return "/media/bg/xeomed_fix.jpeg";
    }
//commit 
    if(params === 617)
    {
      return "/media/bg/kraftwerk.png";
    }

    if(params === 767)
    {
      return "/media/bg/placeholder.jpg";
    }

    if(params === 768)
    {
      return "/media/bg/neue-hoefe-Ottensoos-datev-raum.jpg";
    }

    if(params === 770)
    {
      return "/media/bg/neue-hoefe-Ottensoos-mixed.jpg";
    }

    if(params === 769)
    {
      return "/media/bg/neue-hoefe-neuhof.jpg";
    }

    return "/media/bg/placeholder.jpg";
  }

  return (
    <>
      {/* {isLoading && (
        <div className={classes.loadingCard}>
          <div className='d-flex justify-content-center align-self-center'>
            <SplashScreen width='80' height='80' />
          </div>
        </div>
      )} */}
      {/* <div className={isLoading ? `d-none` : undefined}> */}
      <div>
        <CardMetronic className="container p-0">
          <CardHeaderMetronic>
            <h3 className="card-title">
              <FormattedMessage id="MENU.WS_OVERVIEW" />
            </h3>
          </CardHeaderMetronic>
          <CardBodyMetronic>
            <CardDeck>
              {spaces?.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4}>
                  <Card border="primary" className={classes.root}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="cws" className={classes.avatar}>
                          {getFirstLetters(item.title)}
                        </Avatar>
                      }
                      title={item.title}
                      subheader={item.active === true ? "active" : null}
                    />
                    <CardContent className="p-0">
                      <div>
                        <img
                          className="d-block h-100 w-100"
                          src={getThumbnail(item.id)}
                          alt="..."
                        />
                      </div>
                    </CardContent>
                    <div className="px-5 pt-5 pb-3">
                      <h5>{item?.title}</h5>
                      <div>{item?.addressline1}</div>
                      <div>{item?.addressline2}</div>
                      <div>{item?.city}, {item?.country}</div>
                      <div className="mt-3">
                        <Link
                          to={`/co-working-space/${item?.id}`}
                          className="btn btn-success hover-opacity-90 col-12 col-md-5 col-lg-4"
                        >
                          <FormattedMessage id="OVERVIEW.BOOK_SHOW" />
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </CardDeck>
          </CardBodyMetronic>
        </CardMetronic>
      </div>
    </>
  );
}
