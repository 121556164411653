import axios from "axios";
import store from "../../../../redux/store";
import { getImageIds } from "./imageUploadApi";

const BACKEND_API_URL =
  process.env.REACT_APP_BACKEND_URL || "http://34.72.158.100:3000";
const SPACE_URL = `${BACKEND_API_URL}/protected/portal-space/space/`;
const USER_SPACE_URL = `${BACKEND_API_URL}/protected/portal-space/user/me/space`;
const AUTH_TOKEN = "Bearer " + store.getState().auth.authToken;

/**
 * create opening day content for openingHours array
 */
const setOpeningHoursPerDay = (id, start, end, day) => {
  return {
    dayofweek_id: id,
    start_time: start,
    end_time: end,
    comment: "portalSpaceInsert",
    name: null,
    title: day
  };
};

/**
 * fill openingHours array for edit/create ws with data from every week day
 */
const getOpeningHoursArray = (
  startMo,
  endMo,
  startTu,
  endTu,
  startWe,
  endWe,
  startTh,
  endTh,
  startFr,
  endFr,
  startSa,
  endSa,
  startSu,
  endSu
) => {
  const openingHoursArray = [];
  if (startMo) {
    openingHoursArray.push(setOpeningHoursPerDay(1, startMo, endMo, "Monday"));
  }
  if (startTu) {
    openingHoursArray.push(setOpeningHoursPerDay(2, startTu, endTu, "Tuesday"));
  }
  if (startWe) {
    openingHoursArray.push(
      setOpeningHoursPerDay(3, startWe, endWe, "Wednesday")
    );
  }
  if (startTh) {
    openingHoursArray.push(
      setOpeningHoursPerDay(4, startTh, endTh, "Thursday")
    );
  }
  if (startFr) {
    openingHoursArray.push(setOpeningHoursPerDay(5, startFr, endFr, "Friday"));
  }
  if (startSa) {
    openingHoursArray.push(
      setOpeningHoursPerDay(6, startSa, endSa, "Saturday")
    );
  }
  if (startSu) {
    openingHoursArray.push(setOpeningHoursPerDay(7, startSu, endSu, "Sunday"));
  }

  return openingHoursArray;
};

/**
 * set SpaceBookingTypes array for edit/create ws with flexDesks an meetingRooms
 */
const getSpaceBookingTypesArray = (flexDesks, meetingRooms) => {
  const spaceBookingTypes = [];
  if (meetingRooms.length > 0) {
    meetingRooms.map(room => {
      if (room.roomId) {
        spaceBookingTypes.push({
          id: room.roomId,
          description_id: "1",
          price: room.roomPrice,
          name: room.roomName,
          comment: "portalSpaceInsert",
          amount: null,
          capacity: room.capacity,
          active: true,
          type: "meeting_room",
          description: "",
          other: null
        });
      } else {
        spaceBookingTypes.push({
          description_id: "1",
          price: room.roomPrice,
          name: room.roomName,
          comment: "portalSpaceInsert",
          amount: null,
          capacity: room.capacity,
          active: true,
          type: "meeting_room",
          description: "",
          other: null
        });
      }
    });
  }
  if (flexDesks.length > 0) {
    flexDesks.map(flexDesk => {
      if (flexDesk.id) {
        spaceBookingTypes.push({
          id: flexDesk.id,
          description_id: "2",
          price: flexDesk.price,
          name: "",
          comment: "portalSpaceInsert",
          amount: null,
          capacity: null,
          active: true,
          type: "flexible_desk",
          other: null
        });
      } else {
        spaceBookingTypes.push({
          description_id: "2",
          price: flexDesk.price,
          name: "",
          comment: "portalSpaceInsert",
          amount: null,
          capacity: null,
          active: true,
          type: "flexible_desk",
          other: null
        });
      }
    });
  }
  return spaceBookingTypes;
};

/**
 * function to submit the cws form data
 */
export function formSubmit(
  title,
  flexDesks,
  meetingRooms,
  startMo,
  endMo,
  startTu,
  endTu,
  startWe,
  endWe,
  startTh,
  endTh,
  startFr,
  endFr,
  startSa,
  endSa,
  startSu,
  endSu,
  street,
  zip,
  location,
  country,
  phone,
  lat,
  lng
) {
  // get data from current user
  var firstname = store.getState().auth.user.content.given_name;
  var lastname = store.getState().auth.user.content.family_name;
  var email = store.getState().auth.user.content.email;

  return getImageIds()
    .then(images => {
      var data = JSON.stringify({
        city: location,
        phone: "",
        addressline1: street,
        addressline2: null,
        state: null,
        country: country,
        postalcode: zip,
        territory: "EMEA",
        lat: lat,
        long: lng,
        title: title,
        subtitle: null,
        description: null,
        comment: "portalSpaceInsert",
        code: "",
        category: "1",
        thumbnail: images[0].image_id,
        active: false,
        space_gallery: images,
        space_opening_time: getOpeningHoursArray(
          startMo,
          endMo,
          startTu,
          endTu,
          startWe,
          endWe,
          startTh,
          endTh,
          startFr,
          endFr,
          startSa,
          endSa,
          startSu,
          endSu
        ),
        space_booking_types: getSpaceBookingTypesArray(flexDesks, meetingRooms),
        space_contact: [
          {
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            mobile: null,
            fax: null,
            title: null,
            gender: null,
            description: null,
            comment: "portalSpaceInsert",
            private: false,
            company: "",
            notification_email: true,
            notification_phone: true,
            notification_mobile: false
          }
        ],
        space_network: [],
        space_equipment: [],
        space_extra_service: []
      });

      return data;
    })
    .catch(error => {
      throw Error(error.message);
    })
    .then(data => {
      return axios({
        method: "post",
        url: SPACE_URL,
        headers: {
          Authorization: "Bearer " + store.getState().auth.authToken,
          "Content-Type": "application/json"
        },
        data: data
      });
    })
    .catch(error => {
      throw Error(error.message);
    });
}

/**
 * function to delete a space
 */
export async function deleteSpace(spaceId) {
  try {
    await axios.delete(SPACE_URL + spaceId, {
      method: "DELETE"
    });
  } catch (error) {
    throw Error(error.message);
  }
}

/**
 * function to get contact array
 */
const getContactArray = (spaceId, phone) => {
  const spaceContact = [];
  return getSpaceContact(spaceId)
    .then(contactData => {
      spaceContact.push({
        firstname: contactData[0].firstname,
        lastname: contactData[0].lastname,
        email: contactData[0].email,
        phone: phone,
        mobile: null,
        fax: null,
        title: null,
        gender: null,
        description: null,
        comment: "portalSpaceInsert",
        private: false,
        company: "",
        notification_email: true,
        notification_phone: true,
        notification_mobile: false
      });
      return spaceContact;
    })
    .catch(error => {
      throw Error(error.message);
    });
};

/**
 * function to edit the cws form data
 */
export function formEdit(
  spaceId,
  title,
  flexDesks,
  meetingRooms,
  phone,
  images,
  startMo,
  endMo,
  startTu,
  endTu,
  startWe,
  endWe,
  startTh,
  endTh,
  startFr,
  endFr,
  startSa,
  endSa,
  startSu,
  endSu,
  street,
  zip,
  location,
  country,
  lat,
  lng,
  networkName,
  networkKey
) {
  const network = [];
  if (networkName !== "" && networkKey !== "") {
    network.push({
      ssid: networkName,
      key: networkKey,
      public: "true"
    });
  }

  return getContactArray(spaceId, phone)
    .then(contactData => {
      var data = JSON.stringify({
        city: location,
        phone: "",
        addressline1: street,
        addressline2: null,
        state: null,
        country: country,
        postalcode: zip,
        territory: "EMEA",
        lat: lat,
        long: lng,
        title: title,
        subtitle: null,
        description: null,
        comment: "portalSpaceInsert",
        code: "",
        category: "1",
        thumbnail: images[0].image_id,
        active: false,
        space_gallery: images,
        space_opening_time: getOpeningHoursArray(
          startMo,
          endMo,
          startTu,
          endTu,
          startWe,
          endWe,
          startTh,
          endTh,
          startFr,
          endFr,
          startSa,
          endSa,
          startSu,
          endSu
        ),
        space_booking_types: getSpaceBookingTypesArray(flexDesks, meetingRooms),
        space_contact: contactData,
        space_network: network,
        space_equipment: [],
        space_extra_service: []
      });

      return data;
    })
    .catch(error => {
      throw Error(error.message);
    })
    .then(data => {
      return axios({
        method: "put",
        url: SPACE_URL + spaceId + "/",
        headers: {
          Authorization: "Bearer " + store.getState().auth.authToken,
          "Content-Type": "application/json"
        },
        data: data
      }).catch(error => {
        throw Error(error.message);
      });
    });
}

/**
 * async function to get the spaces from the current user
 */
export async function getSpacesByUser() {
  let spaces = await axios
    .get(USER_SPACE_URL, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw Error(
          `The request was made and the server responded with status ${error.response.status}`
        );
      } else if (error.request) {
        console.log(error.request);
        throw Error(`The request was made but no response was received`);
      } else {
        console.log("Error", error.message);
        throw Error(
          `Something happened in setting up the request that triggered this Error: ${error.message}`
        );
      }
    });

  return spaces.data.type === "error" ? "" : spaces.data;
}

/**
 * async function to get the details from a space by spaceId
 * @param spaceId
 */
export async function getSpaceDetails(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/detail/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the details from all spaces from the current user
 */
export async function getSpacesDetails() {
  try {
    const spacesDetails = [];
    let spaces = await getSpacesByUser();
    for (let space of spaces) {
      const spaceDetailsById = await getSpaceDetails(space.space_id);
      spacesDetails.push(spaceDetailsById[0]);
    }
    return spacesDetails;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the opening hours from a space by spaceId
 * @param spaceId
 */
export async function getSpaceOpeningHours(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/opening-time/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the price from a space by spaceId
 * @param spaceId
 */
export async function getSpacePrice(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/price/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the booking types from a space by spaceId
 * @param spaceId
 */
export async function getSpaceBookingTypes(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/booking-type/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the contact from a space by spaceId
 * @param spaceId
 */
export async function getSpaceContact(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/contact/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the network information from a space by spaceId
 * @param spaceId
 */
export async function getSpaceNetwork(spaceId) {
  try {
    let space = await axios.get(SPACE_URL + spaceId + "/network/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return space.data;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}
