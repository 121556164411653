import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import DatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";
import { useEffect, componentWillMount } from "react";
import SelectedSpace from "./SelectedSpace";
import StepWizard from "react-step-wizard";
import ReviewYourBooking from "./ReviewYourBooking";
import { useSelector } from "react-redux";
import moment from 'moment'

const CoWorkingSpace = () => {
  const [startDate, setStartDate] = useState(null);
  const [time, setTime] = useState(null);
  const [spaceDetail, setDetail] = useState(null);
  const [images, setImages] = useState(null);
  const [availability, setAvailabality] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [nameClass, setClass] = useState("d-block");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  //reservation
  const [checkin, setCheckin] = useState(moment());
  const [checkout, setCheckout] = useState(moment());

  useEffect(() => {

      const fetchData = async () => {
          await axios
              .get(`https://api.avegoo.com/protected/app/space/${id}/detail/`)
              .then((res) => setDetail(res.data[0]))
              .catch((err) => console.log(err));
          await axios
                .get(`https://api.avegoo.com/protected/app/space/${id}/images/`)
                .then(async res => {
                    const imageList = res.data
                    await imageList.forEach(async item =>  {
                        const tmpImageId = item.image_id;
                        await axios.get(`https://api.avegoo.com/protected/app/space/image/${tmpImageId}`, {responseType: 'arraybuffer'}).then(ress => {
                            const raw = Buffer.from(ress.data).toString('base64');
                            const data = "data:" + ress.headers["content-type"] + ";base64,"+raw;
                            item.imageUrl = data;
                        }).catch(errr => {
                            console.log('Issue with loading images: ', errr);
                        })
                    });
                    setImages(imageList)
                    setIsLoading(false);
                    setStartDate('');
                    //console.log(imageList);
                    // reload storage for updating the component.
                    console.log("Done Loading images");
                })
                .catch((err) => console.log(err));

          await axios
              .get(`https://api.avegoo.com/protected/app/space/${id}/opening-time/`)
              .then((res) => setAvailabality(res.data))
              .catch((err) => console.log(err));
          //setIsLoading(false);
      }
      // call the function
      fetchData().catch(console.error);

  }, []);
    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    if (!isLoading) {
        console.log('TestImages:', images)
        return (
            <>
                <StepWizard>
                    <SelectedSpace
                        nameClass={nameClass}
                        setClass={setClass}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        id={id}
                        spaceDetail={spaceDetail}
                        setStartDate={setStartDate}
                        setTime={setTime}
                        availability={availability}
                        startDate={startDate}
                        time={time}
                        images={images}
                        checkin={checkin}
                        setCheckin={setCheckin}
                        checkout={checkout}
                        setCheckout={setCheckout}
                    />
                    <ReviewYourBooking
                        nameClass={nameClass}
                        setClass={setClass}
                        id={id}
                        date={startDate}
                        enddate={time}
                        selectedDay={selectedDay}
                        checkin={checkin}
                        checkout={checkout}
                    />
                </StepWizard>
            </>
        );
    }
};

export default CoWorkingSpace;
