import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {FormattedMessage} from "react-intl";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertsDialog({
  onClose,
  open,
  id,
  date,
  time,
  selectedDay,
  checkin,
  checkout
}) {
  console.log("selected", selectedDay);
  const history = useHistory();
  console.log(id);
  const onSubmit = () => {
    axios
      .get(`https://api.avegoo.com/protected/app/space/${id}/booking-type/`)
      .then((res) => {
        console.log(res);
        /*
            "date_checkin" : "2021-02-23 13:10:11",
            "date_checkout" : "2021-02-23 20:10:11"
         */
        const checkingDate = checkin.format('YYYY-MM-DD HH:mm:ss');
        const checkoutDate = checkout.format('YYYY-MM-DD HH:mm:ss');
        const data = {
          space_id: id,
          booking_type: "2",
          booking_type_id: res.data[0].id,
          date_checkin: checkingDate,
          date_checkout: checkoutDate,
        };
        console.log(data);
        axios
          .post(
            `https://api.avegoo.com/protected/app/space/${id}/reservation`,
            data
          )
          .then((res) => {
            console.log(res);
            onClose();
            history.push("/overview");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div className="mt-10">
            <div className="h-80px w-80px border border-1 border-success rounded-circle rounded-lg d-flex justify-content-center align-items-center mx-auto">
              <i className="fa fa-check text-success display-4 mb-0" />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="px-0 mx-auto text-center col-12 col-md-10 col-lg-9">
              <h4 className="mb-0 font-weight-normal">
                <FormattedMessage id="VALIDATION.BOOKING.CONFIMRATION" />
              </h4>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="px-0 mx-auto text-center col-12 col-md-10 col-lg-9 mb-10">
            <div
              className="d-flex justify-content-center"
              style={{ gap: "0.8rem" }}
            >
              <button
                type="button"
                className="btn btn-lg btn-light"
                onClick={onClose}
              >
                <FormattedMessage id="DIALOG.DENY" />
              </button>
              <button
                  type="button"
                  className="btn btn-success btn-lg "
                  onClick={onSubmit}
              >
                <FormattedMessage id="DIALOG.CONFIRM" />
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
