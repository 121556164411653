import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

const initialValues = {
  username: "",
  password: ""
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: "VALIDATION.MIN_LENGTH"
          },
          { min: "6" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "VALIDATION.MAX_LENGTH"
          },
          { max: "50" }
        )
      )
      .test(
        "whitespace-check",
        intl.formatMessage({
          id: "VALIDATION.WHITESPACE"
        }),
        value => {
          return value ? !value.includes(" ") : false;
        }
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    // TODO: Meldung wenn Passwort nicht zum User aus der DB passt.
    password: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.username, values.password)
          .then(({ data: { access_token } }) => {
            disableLoading();
            props.login(access_token);
          })
          .catch(error => {
            disableLoading();
            setSubmitting(false);
            if (error.response) {
              if (
                error.response.data.type === "invalid_grant" &&
                error.response.data.description ===
                  "Account is not fully set up"
              ) {
                setStatus(
                  intl.formatMessage({
                    id: "VALIDATION.EMAIL_VERIFY"
                  })
                );
              } else {
                setStatus(
                  intl.formatMessage({
                    id: "VALIDATION.INVALID_LOGIN"
                  })
                );
              }
            } else {
              setStatus(
                intl.formatMessage({
                  id: "VALIDATION.FAILED_LOGIN"
                })
              );
            }
          });
      }, 1000);
    }
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.INFORMATION" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "FORM.INPUT.EMAIL_USERNAME"
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="text-danger">{formik.errors.username}</div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <a
            href="https://auth.avegoo.com/auth/realms/avegoo-nodejs/login-actions/reset-credentials?client_id=nodejs-connect"
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </a>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              <FormattedMessage id="AUTH.LOGIN.BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
