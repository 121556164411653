import axios from "axios";
import store from "../../../../redux/store";

const BACKEND_API_URL =
  process.env.REACT_APP_BACKEND_URL || "http://34.72.158.100:3000";
const IMAGE_URL = `${BACKEND_API_URL}/protected/portal-space/space/image/`;
const IMAGE_BY_SPACE_URL = `${BACKEND_API_URL}/protected/portal-space/space/`;
export const IMAGE_UPLOAD_URL = `${BACKEND_API_URL}/protected/portal-space/space/image/upload`;
export const AUTH_TOKEN = "Bearer " + store.getState().auth.authToken;

const useErrorMessage = error => {
  switch (error) {
    case error.response:
      console.log(error.response);
      throw Error(
        `The request was made and the server responded with status ${error.response.status}`
      );
    case error.request:
      console.log(error.request);
      throw Error(`The request was made but no response was received`);
    case error.message:
      console.log("Error", error.message);
      throw Error(
        `Something happened in setting up the request that triggered this Error: ${error.message}`
      );
    default:
      console.log(error);
  }
};

/**
 * Checking for Network Error in this file is to know if the user token is expired.
 * In future we want to check directly if the the user token is expired by API.
 * This is the fastest solution right now.
 */

/**
 * async function to get all images the user has uploaded
 */
export async function getUserUploadedImages() {
  let networkError = "";
  let images = await axios
    .get(IMAGE_UPLOAD_URL, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    })
    .catch(error => {
      useErrorMessage(error);
    });
  return networkError ? networkError : images.data;
}

/**
 * async function to get one image the user has uploaded by imageId
 */
export async function getUserUploadedImageById(imageId) {
  let image = await axios
    .get(IMAGE_URL + imageId, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    })
    .catch(error => {
      useErrorMessage(error);
    });
  return image;
}

/**
 * async function to get the needed data of all images the user has uploaded
 */
export async function getUserUploadedImagesData() {
  try {
    const imagesData = [];
    let images = await getUserUploadedImages();
    if (images) {
      for (let image of images) {
        try {
          const imageById = await getUserUploadedImageById(image.image_id);
          if (imageById) {
            imagesData.push({
              imageId: image.image_id,
              originalname: image.originalname,
              url: imageById.config.url
            });
          }
        } catch (error) {
          console.log(error.message);
          throw Error(error.message);
        }
      }
    }
    return imagesData;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get an array of all imageIds from user uploaded images
 */
export async function getImageIds() {
  try {
    const imageIds = [];
    let images = await getUserUploadedImages();
    if (images) {
      for (let image of images) {
        imageIds.push({
          image_id: image.image_id
        });
      }
    }
    return imageIds;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the initialImagess like filepond does need it for his image upload
 */
export async function getInitialImages() {
  try {
    const initialImages = [];
    let imagesData = await getUserUploadedImagesData();
    if (imagesData) {
      for (let image of imagesData) {
        initialImages.push({
          source: image.url,
          headers: {
            Authorization: AUTH_TOKEN
          },
          options: {
            type: "local"
          },
          imageId: image.imageId,
          originalname: image.originalname
        });
      }
    }
    return initialImages;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to delete uploaded images
 * @param imageId
 */
export async function deleteImage(imageId) {
  try {
    await axios.delete(IMAGE_UPLOAD_URL + "/" + imageId, {
      method: "DELETE",
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get the images from a space by spaceId
 * @param spaceId
 */
export async function getSpaceImages(spaceId) {
  let images = await axios
    .get(IMAGE_BY_SPACE_URL + spaceId + "/images/", {
      headers: {
        Authorization: AUTH_TOKEN
      }
    })
    .catch(error => {
      useErrorMessage(error);
    });

  const initialImages = [];
  try {
    for (let image of images.data) {
      const spaceImage = await getSpaceImageById(image.image_id);
      initialImages.push({
        source: spaceImage.config.url,
        headers: {
          Authorization: AUTH_TOKEN
        },
        options: {
          type: "local"
        },
        imageId: image.image_id,
        originalname: image.originalname
      });
    }
    return initialImages;
  } catch (error) {
    console.log(error.message);
    throw Error(error.message);
  }
}

/**
 * async function to get an image from a space by imageId
 * @param imageId
 */
export async function getSpaceImageById(imageId) {
  return await axios
    .get(IMAGE_URL + imageId, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    })
    .catch(error => {
      useErrorMessage(error);
    });
}
