import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from "@material-ui/core";
import Draggable from "react-draggable";

export function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Dialog(props) {
  const { title, message, link, aggree, disagree } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MaterialDialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && (
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {disagree && disagree}
          <Link to={link}>
            <Button onClick={handleClose} color="primary" autoFocus>
              {aggree ? (
                aggree
              ) : (
                <FormattedMessage id="FORM.GENERAL.AGREE_BUTTON" />
              )}
            </Button>
          </Link>
        </DialogActions>
      </MaterialDialog>
    </>
  );
}
