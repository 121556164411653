import axios from "axios";
import qs from "qs";

const BACKEND_API_URL =
  process.env.REACT_APP_BACKEND_URL || "http://34.72.158.100:3000";
export const LOGIN_URL = `${BACKEND_API_URL}/login`;
export const REGISTER_URL = `${BACKEND_API_URL}/signup`;
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
const ME_URL = `${BACKEND_API_URL}/protected/app/user/me`;

export function login(username, password) {
  var data = qs.stringify({
    client_id: "nodejs-connect",
    grant_type: "password",
    client_secre: "mySecret",
    username: username,
    password: password,
    scope: "openid"
  });
  return axios.post(LOGIN_URL, data);
}

export function register(email, firstname, lastname, username, password) {
  var data = qs.stringify({
    email: email,
    firstName: firstname,
    lastName: lastname,
    password: password,
    "password-confirm": password,
    username: username
  });
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
