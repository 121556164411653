import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import Dialog from "../components/Dialog";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  acceptConditions: false
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: "VALIDATION.MIN_LENGTH"
          },
          { min: "3" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "VALIDATION.MAX_LENGTH"
          },
          { max: "50" }
        )
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    lastname: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: "VALIDATION.MIN_LENGTH"
          },
          { min: "3" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "VALIDATION.MAX_LENGTH"
          },
          { max: "50" }
        )
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    email: Yup.string()
      .test(
        "whitespace-check",
        intl.formatMessage({
          id: "VALIDATION.WHITESPACE"
        }),
        value => {
          return value ? !value.includes(" ") : false;
        }
      )
      .email(
        intl.formatMessage({
          id: "VALIDATION.EMAIL_FORMAT"
        })
      )
      .min(
        6,
        intl.formatMessage(
          {
            id: "VALIDATION.MIN_LENGTH"
          },
          { min: "6" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "VALIDATION.MAX_LENGTH"
          },
          { max: "50" }
        )
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    username: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: "VALIDATION.MIN_LENGTH"
          },
          { min: "3" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "VALIDATION.MAX_LENGTH"
          },
          { max: "50" }
        )
      )
      .test(
        "whitespace-check",
        intl.formatMessage({
          id: "VALIDATION.WHITESPACE"
        }),
        value => {
          return value ? !value.includes(" ") : false;
        }
      )
      .matches(
        /^[a-zA-Z\d-_.@!?+]+$/,
        intl.formatMessage({
          id: "VALIDATION.USERNAME_CHARS"
        })
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d*.!@$%^&(){}[\]:;<>,.?/~_+-=|#]{8,}$/,
        intl.formatMessage({
          id: "VALIDATION.PASSWORD"
        })
      )
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "VALIDATION.PASSWORD_MATCH"
          })
        )
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({
        id: "VALIDATION.AGREEMENT_AGB_REQUIRED"
      })
    ),
    acceptConditions: Yup.bool().required(
      intl.formatMessage({
        id: "VALIDATION.AGREEMENT_CONDITIONS_REQUIRED"
      })
    )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      register(
        values.email,
        values.firstname,
        values.lastname,
        values.username,
        values.password
      )
        .then(() => {
          // TODO: after redirect to the login url is refilled with the registered credentials
          setLoading(false);
          setUsername(values.username);
          setEmail(values.email);
          setOpenDialog(true);
        })
        .catch(error => {
          setSubmitting(false);
          if (error.response.data["invalid-params"] === "email") {
            setStatus(
              intl.formatMessage({
                id: "VALIDATION.EMAIL_TOOKEN"
              })
            );
          } else if (error.response.data["invalid-params"] === "username") {
            setStatus(
              intl.formatMessage({
                id: "VALIDATION.USERNAME_TOOKEN"
              })
            );
          } else {
            setStatus(
              intl.formatMessage({
                id: "VALIDATION.INVALID_LOGIN"
              })
            );
          }
          setLoading(false);
        });
    }
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESC" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Firstname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "FORM.INPUT.FIRSTNAME" })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstname"
            )}`}
            name="firstname"
            {...formik.getFieldProps("firstname")}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="text-danger">{formik.errors.firstname}</div>
          ) : null}
        </div>
        {/* end: Firstname */}

        {/* begin: Lastname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "FORM.INPUT.LASTNAME" })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastname"
            )}`}
            name="lastname"
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="text-danger">{formik.errors.lastname}</div>
          ) : null}
        </div>
        {/* end: Lastname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "FORM.INPUT.EMAIL" })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "FORM.INPUT.USERNAME" })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="text-danger">{formik.errors.username}</div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "FORM.INPUT.PASSWORD" })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "FORM.INPUT.CONFIRM_PASSWORD"
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="text-danger">{formik.errors.changepassword}</div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox justify-content-between">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <a
              href="http://avegoo.de/agb"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="VALIDATION.AGREEMENT_AGB" />
            </a>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="text-danger">{formik.errors.acceptTerms}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label className="checkbox justify-content-between">
            <input
              type="checkbox"
              name="acceptConditions"
              className="m-1"
              {...formik.getFieldProps("acceptConditions")}
            />
            <a
              href="http://avegoo.de/vereinbarung-fuer-partner/"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="VALIDATION.AGREEMENT_CONDITIONS" />
            </a>
            <span />
          </label>
          {formik.touched.acceptConditions && formik.errors.acceptConditions ? (
            <div className="text-danger">{formik.errors.acceptConditions}</div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="FORM.GENERAL.CANCEL_BUTTON" />
            </button>
          </Link>
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.values.acceptTerms ||
              !formik.values.acceptConditions
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              <FormattedMessage id="FORM.GENERAL.SUBMIT_BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          {openDialog && (
            <Dialog
              title={intl.formatMessage(
                {
                  id: "FORM.SUBMIT.REGISTRATION_TITLE"
                },
                { username: username, email: email }
              )}
              message={intl.formatMessage(
                {
                  id: "FORM.SUBMIT.REGISTRATION"
                },
                { username: username, email: email }
              )}
              link="/auth/login"
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
