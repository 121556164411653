import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
//import { DashboardPage } from "./pages/DashboardPage";
//import AddWorkingSpace from "./pages/AddWorkingSpace";
//import MySpaces from "./pages/MySpaces";
//import ExtraData from "./modules/MySpaces/ExtraData";
import Overview from "./modules/Avegoo/Overview";
import Bookings from "./modules/Avegoo/Bookings";
import BookingsDetails from "./modules/Avegoo/BookingsDetails";
import CoWorkingSpace from "./modules/Avegoo/CoWorkingSpace";
import ReviewYourBooking from "./modules/Avegoo/ReviewYourBooking";

export default function BasePage() {
  return (
    <Switch>
      <Redirect exact={true} from="/" to="/overview" />
      <ContentRoute path="/overview" component={Overview} />
      <ContentRoute path="/bookings" component={Bookings} />
      <ContentRoute path="/bookings-details/:id/:space_id" component={BookingsDetails} />
      <ContentRoute path="/co-working-space/:id" component={CoWorkingSpace} />
      <ContentRoute path="/review-your-booking" component={ReviewYourBooking} />
      {/* {<Redirect exact={true} from="/" to="/my-spaces" />} */}
      {/* <ContentRoute path='/dashboard' component={DashboardPage} />
      <ContentRoute path='/add-working-space' component={AddWorkingSpace} />
      <ContentRoute path='/my-spaces' component={MySpaces} />
      <ContentRoute path='/my-spaces/extra-data' component={ExtraData} /> */}
    </Switch>
  );
}
