import React, { useState } from "react";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import AlertsDialog from "../Auth/components/AlertsDialog";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import {FormattedMessage} from "react-intl";
import {colorBrewer} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import moment from 'moment'


const ReviewYourBooking = ({
  id,
  date,
  enddate,
  selectedDay,
  previousStep,
  nameClass,
  setClass,
  checkin,
  checkout
}) => {
  const user = useSelector((state) => state.auth.user.content);
  // const { id, date, enddate } = props.location;
  const [open, setOpen] = useState(false);
  const [spaceDetail, setDetail] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
    setVisible(false);
  };
  const handleClickOpenHint = () => {
    setVisible(true);
  };

  console.log(id, date, enddate, user);

  useEffect(() => {
    axios
      .get(`https://api.avegoo.com/protected/app/space/${id}/detail/`)
      .then((res) => {
        setDetail(res.data[0]);
        console.log("response", res);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <>
      <div>
        <CardMetronic className="container p-0">
          <CardHeaderMetronic>
            <div className="pt-10 pb-15 px-0 mx-auto col-12 col-md-10 col-lg-9 col-xxl-8">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex align-items-center mt-8">
                  <div>
                    <div className="h-65px w-65px bg-success-o-35 rounded-lg d-flex justify-content-center align-items-center text-success font-weight-boldest h3 mb-0">
                      <i className="fa fa-check text-success h2 mb-0" />
                    </div>
                  </div>
                  <div className="mx-4">
                    <h2 className="font-weight-boldest"><FormattedMessage id="CONTENT.GENERAL.BOOKING" /></h2>
                    <h5 className="mb-0 text-dark-25"><FormattedMessage id="CONTENT.GENERAL.COWORKINGDETAILS" /></h5>
                  </div>
                </div>

                <div className="mt-8 d-flex align-items-center">
                  <div className="text-dark-25">
                    <i className="fa fa-2x fa-arrow-right" />
                  </div>
                </div>

                <div className="d-flex align-items-center mt-8">
                  <div>
                    <div className="h-65px w-65px bg-success-o-35 text-success rounded-lg d-flex justify-content-center align-items-center font-weight-boldest h3 mb-0">
                      2
                      {/* <i className='fa fa-check text-success h2 mb-0' /> */}
                    </div>
                  </div>
                  <div className="mx-4">
                    <h2 className="font-weight-boldest"><FormattedMessage id="CONTENT.GENERAL.TITLE_STEP2" /></h2>
                    <h5 className="mb-0 text-dark-25"><FormattedMessage id="CONTENT.GENERAL.BOOKING_SUBLINE" /></h5>
                  </div>
                </div>
              </div>
            </div>
          </CardHeaderMetronic>
          <CardBodyMetronic>
            <div className="px-0 mx-auto col-12 col-md-10 col-lg-9 col-xxl-8">
              <ul className="nav flex-column" style={{ gap: "1.6rem" }}>
                <li>
                  <div className="mb-8">
                    <div className="co-work-heading">
                      <h3 className="mb-3 font-weight-boldest">
                        <FormattedMessage id="FORM.GENERAL.REVIEW"/>
                      </h3>
                      <h5 className="mb-0 font-weight-bold text-dark-50">
                        <FormattedMessage id="FORM.GENERAL.REVIEW.TXT"/>
                      </h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5 className="mb-3 font-weight-boldest text-dark-65 text-capitalize">
                      <FormattedMessage id="INFORMATION.FORM.TITLE.USER.INFO" />
                    </h5>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      {user.name}
                    </h6>
                    <a href="mailto:nick.stone@gmail.com">
                      <h6 className="mb-1 text-dark-50">{user.email}</h6>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <h5 className="mb-3 font-weight-boldest text-dark-65 text-capitalize">
                      <FormattedMessage id="INFORMATION.FORM.TITLE.COWOKRING" />
                    </h5>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      {spaceDetail?.title}
                    </h6>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      {spaceDetail?.addressline1},
                    </h6>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      {spaceDetail?.city}, {spaceDetail?.country}
                    </h6>
                  </div>
                </li>
                <li>
                  <div>
                    <h5 className="mb-3 font-weight-boldest text-dark-65 text-capitalize">
                      <FormattedMessage id="FORM.GENERAL.BOOKING.DETAILS"/>
                    </h5>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      <FormattedMessage id="OVERVIEW.DATE.TXT"/>: {moment(date).format("DD.MM.YYYY")}
                    </h6>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      <FormattedMessage id="OVERVIEW.BOOK.FROM"/>: {checkin.format("HH:mm") || ''}
                    </h6>
                    <h6 className="mb-1 text-capitalize text-dark-50">
                      <FormattedMessage id="OVERVIEW.BOOK.TO"/>: {checkout.format("HH:mm") || ''}
                    </h6>
                    <h6 className="mb-1 text-capitalize text-dark-50" hidden>
                      <FormattedMessage id="OVERVIEW.BOOKING_TIME.TXT"/>: {enddate}
                    </h6>
                  </div>
                </li>
                <li>
                  <div>
                    <h5 className="mb-3 font-weight-boldest text-dark-65 text-capitalize">
                      <FormattedMessage id="FORM.GENERAL.NOTICE" />
                    </h5>
                    <h6 className="mb-1 text-dark-50">
                      <FormattedMessage id="VALIDATION.AGREEMENT_BOOKING" />
                    </h6>
                  </div>
                </li>
                <li>
                  <label className="checkbox justify-content-between">
                    <input
                        type="checkbox"
                        name="acceptCovid3GRules"
                        className="m-1"
                        checked={isChecked}
                        onChange={handleOnChange}
                    />
                    <a style={{color: "#63BF71"}}>
                      <b><h5><FormattedMessage id="VALIDATION.AGREEMENT_COVID" /></h5></b>
                    </a>
                    <span/>
                  </label>
                  <div className={visible ? 'valid-feedback d-block text-danger' : 'valid-feedback'}><FormattedMessage id="VALIDATION.AGREEMENT_COVID_HINT" /></div>
                </li>
              </ul>
            </div>
          </CardBodyMetronic>
          <div className="card-footer">
            <div className="d-flex align-items-center justify-content-between">
              <Link
                onClick={() => {
                  setClass("d-block");
                  previousStep();
                }}
                // to={`/co-working-space/id`}
                className="btn btn-lg text-success bg-success-o-35 hover-opacity-79 text-capitalize"
              >
                <FormattedMessage id="FORM.GENERAL.BACK_BUTTON" />
              </Link>

              <Link
                onClick={isChecked ? handleClickOpen : handleClickOpenHint}
                to
                className={isChecked ? "btn btn-lg btn-success hover-opacity-90" : "btn btn-lg text-success bg-success-o-35 hover-opacity-79 text-capitalize"}
              >
                <FormattedMessage id="FORM.GENERAL.BOOK_BUTTON" />
              </Link>
            </div>
          </div>
        </CardMetronic>
      </div>

      <AlertsDialog
        onClick={handleClickOpen}
        onClose={handleClose}
        id={id}
        date={date}
        time={enddate}
        open={open}
        selectedDay={selectedDay}
        checkin={checkin}
        checkout={checkout}
      />
    </>
  );
};

export default ReviewYourBooking;
