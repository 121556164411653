import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CardDeck } from "react-bootstrap";
import { Avatar, Card, CardHeader, CardContent } from "@material-ui/core";

import { Grid, makeStyles } from "@material-ui/core";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    margin: 8,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  avatar: {
    backgroundColor: "#9AD9A3",
  },
  addIcon: {
    fontSize: 220,
    color: "#A1A5A6",
  },
  addCardContent: {
    margin: 8,
    display: "flex",
    justifyContent: "center",
  },
  addCard: {
    display: "grid",
  },
  delete: {
    marginLeft: "auto",
  },
  loadingCard: {
    height: "50%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Overview() {
  const authToken = useSelector((state) => state.auth.authToken);
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;
  const [bookings, setBookings] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const classes = useStyles();

  const cardsList = [{}, {}, {}, {}, {}, {}];

  useEffect(() => {
    axios
      .get(
        `https://api.avegoo.com/protected/app/user/booking/reservation/list`)
      .then((res) => setBookings(res.data.bookings))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://api.avegoo.com/protected/app/space/nearby?lat=48.264605&long=8.849598`)
      .then((res) => setSpaces(res.data))
      .catch((err) => console.log(err));
  }, []);

  function getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstLetters;
  }

  function getReservationStateText(booking)
  {
    if (booking.reservation_status  == "1")
      return "Abgeschlossen";
  if (booking.reservation_status  == "2")
      return "Reservierung bestätigt";
  if (booking.reservation_status  == "3")
      return "Reservierung wird durchgeführt";
  if (booking.reservation_status  == "4")
      return "Warte auf Bestätigung"
  if (booking.reservation_status  == "5")
      return "Done";
  if (booking.reservation_status  == "6")
      return "Maintenance";
  if (booking.reservation_status  == "7")
      return "No Show";
  if (booking.reservation_status  == "8")
      return "Reservierung abgelehnt";
  }

  function getFormattedCheckinDate(booking)
  {
    var checkin = booking?.reservation?.date_checkin;
    return new Date(checkin).toLocaleDateString('de-DE');
  }

  function getFormattedTime(booking)
  {
    var checkin = booking?.reservation?.date_checkin;
    var checkout = booking?.reservation?.date_checkout; 
    return new Date(checkin).toLocaleTimeString('de-DE') +" - " + new Date(checkout).toLocaleTimeString('de-DE');
  }

  function getSpace(space_id)
  {
    for(var i = 0; i<spaces.length; i++)
    {
      if(spaces[i].id == space_id)
      return spaces[i];
    }
    return null;
  }

  function getThumbnail(params) {
    if(params === 616)
    {
      return "/media/bg/xeomed_flex.jpeg";
    }

    if(params === 581)
    {
      return "/media/bg/xeomed_fix.jpeg";
    }

    if(params === 617)
    {
      return "/media/bg/kraftwerk.png";
    }

    return "/media/bg/placeholder.jpg";
  }

  return (
    <>
      {/* {isLoading && (
        <div className={classes.loadingCard}>
          <div className='d-flex justify-content-center align-self-center'>
            <SplashScreen width='80' height='80' />
          </div>
        </div>
      )} */}
      {/* <div className={isLoading ? `d-none` : undefined}> */}
      <div>
        <CardMetronic className="container p-0">
          <CardHeaderMetronic>
            <h3 className="card-title">
              <FormattedMessage id="MENU.WS_OVERVIEW" />
            </h3>
          </CardHeaderMetronic>
          <CardBodyMetronic>
            <CardDeck>
             {bookings?.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4}>
                  <Card border="primary" className={classes.root}>
                    <CardHeader
                    
                      title={getReservationStateText(item)}
                      subheader={getFormattedCheckinDate(item)}
                    />
                    <CardContent className="p-0">
                      <div>
                        <img
                          className="d-block h-100 w-100"
                          src={getThumbnail(item?.reservation?.space_id)}
                          alt="..."
                        />
                      </div>
                    </CardContent>
                    <div className="px-5 pt-5 pb-3">
                      <h5>{getSpace(item?.reservation?.space_id)?.title}</h5>
                      <div>{getSpace(item?.reservation?.space_id)?.city}</div>
                      <div>{getFormattedCheckinDate(item)}</div>
                       <div>{getFormattedTime(item)}</div>
                      <div>{getReservationStateText(item)}</div>
                      { <div className="mt-3">
                        <Link
                          to={`/bookings-details/${item?.id}/${item?.reservation?.space_id}`}
                          className="btn btn-success hover-opacity-90 col-12 col-md-5 col-lg-4"
                        >
                          <FormattedMessage id="OVERVIEW.BOOK_SHOW" />
                        </Link>
                      </div> }
                    </div>
                  </Card>
                </Grid>
              )) }
            </CardDeck>
          </CardBodyMetronic>
        </CardMetronic>
      </div>
    </>
  );
}
