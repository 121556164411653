/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { FormattedMessage } from "react-intl";
import { getSpacesByUser } from "../../../../app/modules/Auth/_redux/spacesApi";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link } from "react-router-dom";

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
  };
  // TODO: Aside wird immer komplett neu geladen, somit ist der activeTab immer der der in useState steht und nicht der wirklich aktive
  // z.B. immer tabs.tabId1 oder tabs.tabId2
  const [activeTab, setActiveTab] = useState();
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  const [workingSpaceId, setWorkingSpaceId] = useState("");
  /*
  getSpacesByUser().then((spaceData) => {
    if (spaceData.length > 0) {
      setWorkingSpaceId(spaceData[0].space_id);
    }
  });
  */

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {/* begin::Item */}
              {/* <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Dashboard"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="dashboard">Dashboard</Tooltip>
                  }
                >
                  <a
                    href="#dashboard"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                      tabs.tabId1 && "active"}`}
                    data-toggle="tab"
                    data-target={`#${tabs.tabId1}`}
                    role="tab"
                    onClick={() => handleTabChange(tabs.tabId1)}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Layout/Layout-4-blocks.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li> */}
              {/* end::Item */}

              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="my-spaces">
                      <FormattedMessage id="MENU.WS_OVERVIEW" />
                    </Tooltip>
                  }
                >
                  <Link
                    to="/overview"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                      tabs.tabId1 && "active"}`}
                    data-toggle="tab"
                    data-target={`#${tabs.tabId1}`}
                    onClick={() => handleTabChange(tabs.tabId1)}
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-2x">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                      />
                    </span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* <li
                className='nav-item mb-3'
                data-toggle='tooltip'
                data-placement='rigth'
                data-container='body'
                data-boundary='window'
              >
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip id='add-ws'>
                      <FormattedMessage id='MENU.ADD_WS' />
                    </Tooltip>
                  }
                >
                  <a
                    href='/add-working-space'
                    className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                      tabs.tabId2 && 'active'}`}
                    data-toggle='tab'
                    data-target={`#${tabs.tabId2}`}
                    onClick={() => handleTabChange(tabs.tabId2)}
                    role='tab'
                  >
                    <span className='svg-icon svg-icon-2x'>
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Files/File-plus.svg'
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li> */}
              {
                /*
                workingSpaceId && (
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="extra-data">
                        <FormattedMessage id="MENU.EXTRA_DATA" />
                      </Tooltip>
                    }
                  >
                    <a
                      href={`/my-spaces/extra-data/id=${workingSpaceId}`}
                      className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabs.tabId3 && "active"}`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId3}`}
                      onClick={() => handleTabChange(tabs.tabId3)}
                      role="tab"
                    >
                      <DescriptionIcon fontSize="large" />
                    </a>
                  </OverlayTrigger>
                </li>
              )
              */
              }
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                      id="kt_aside_toggle"
                    >
                      <i className="ki ki-bold-arrow-back icon-sm" />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            {/* end::Aside Toggle */}

            {/* begin::Search */}
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

            {/* begin::Notifications */}
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-notifications">Notifications</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Notifications */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}

            {/* begin::Languages*/}
            <LanguageSelectorDropdown />
            {/* end::Languages */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideSearch isActive={activeTab === tabs.tabId1} />
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
