import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export function ErrorPageAvegoo() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg4.jpg")})`
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          OOPS! <FormattedMessage id="CONTENT.GENERAL.ERROR" />
        </p>
        <Link to="/logout">
          <Button className="btn btn-primary font-weight-bold px-9 py-4 mr-4">
            <FormattedMessage id="FORM.GENERAL.LOGIN_BUTTON" />
          </Button>
        </Link>
      </div>
    </div>
  );
}
