import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {}
  };

  onMarkerClick = (props, marker) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    const mapStyles = {
      width: "100%",
      height: "500px",
      borderRadius: "0.42rem"
    };
    const divStyles = {
      height: "500px",
      width: "100%",
      position: "relative"
    };

    return (
      <div style={divStyles}>
        <Map
          google={this.props.google}
          onClick={this.onMapClicked}
          zoom={this.props.lat && this.props.lng ? 16 : 7}
          initialCenter={
            this.props.lat && this.props.lng
              ? { lat: this.props.lat, lng: this.props.lng }
              : {
                  lat: 48.28086,
                  lng: 8.84929
                }
          }
          center={
            this.props.lat && this.props.lng
              ? { lat: this.props.lat, lng: this.props.lng }
              : { lat: 48.28086, lng: 8.84929 }
          }
          style={mapStyles}
          className={"map"}
        >
          <Marker
            onClick={this.onMarkerClick}
            title={
              this.props.lat && this.props.lng
                ? this.props.workingSpace
                : "avegoo"
            }
            name={"currentPosition"}
            position={
              this.props.lat && this.props.lng
                ? { lat: this.props.lat, lng: this.props.lng }
                : { lat: 48.28086, lng: 8.84929 }
            }
          />
          <InfoWindow
            onClose={this.onInfoWindowClose}
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              {this.props.lat && this.props.lng
                ? this.props.workingSpace
                : "avegoo"}
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDCx2bnMUpNsj3WxesM-n3dZeTD4EZFX5A"
})(MapContainer);
