import React from "react";
import {
  Card as CardMetronic,
  CardHeader as CardHeaderMetronic,
  CardBody as CardBodyMetronic,
} from "../../../_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import MapContainer from '../AddWorkingSpace/MapContainer';
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/core";
import moment from 'moment'
import { injectIntl } from 'react-intl';
import { set } from "date-fns/esm";

const SelectedSpace = ({
  spaceDetail,
  setStartDate,
  time,
  availability,
  startDate,
  setTime,
  nextStep,
  id,
  images,
  selectedDay,
  setSelectedDay,
  nameClass,
  setClass,
  intl,
  checkin,
  setCheckin,
  checkout,
  setCheckout
}) => {
  const { formatMessage } = intl;
  const [error, setError] = useState(null);
  const [imagesTmp, setImagesTmp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState('');

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  var fullDayFree = true;
  var morningFree = true;
  var afternoonFree = true;

  const handleSubmit = (e) => {
    e.preventDefault();
    setClass("d-none");
    nextStep();
  };

  const useStyles = makeStyles(() => ({
    root: {
      margin: 8,
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    avatar: {
      backgroundColor: '#9AD9A3',
    },
    addIcon: {
      fontSize: 220,
      color: '#A1A5A6',
    },
    addCardContent: {
      margin: 8,
      display: 'flex',
      justifyContent: 'center',
    },
    addCard: {
      display: 'grid',
    },
    delete: {
      marginLeft: 'auto',
    },
    loadingCard: {
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
    },
  }));


  useEffect(() => {
    const fetchData = async () => {
    var url = `https://api.avegoo.com/protected/app/space/`+id+`/bookingAvailable?date_checkin=`+startDate+`&space_id=`+id;
    
    await axios
      .get(url)
      .then((res) =>  checkBookingPosible(res.data))
      .catch((err) => console.log(err));
    }
  
    /*
    const fetchData = async () => {
      await axios
          .get(`https://api.avegoo.com/protected/app/space/${id}/images/`)
          .then(async res => {
            const imageList = res.data
            await imageList.forEach(async item =>  {
              const tmpImageId = item.image_id;
              await axios.get(`https://api.avegoo.com/protected/portal-space/space/image/${tmpImageId}`, {responseType: 'arraybuffer'}).then(ress => {
                const raw = Buffer.from(ress.data).toString('base64');
                const data = "data:" + ress.headers["content-type"] + ";base64,"+raw;
                item.imageUrl = data;
              }).catch(errr => {
                console.log('Issue with loading images: ', errr);
              })
            });
            setImagesTmp(imageList)
            setIsLoading(false);
            //console.log(imageList);
            // reload storage for updating the component.
            console.log("2Done Loading images");
          })
          .catch((err) => console.log(err));
    }
    fetchData().catch(error => console.log(error));
    */

    console.log(startDate);
    if (startDate) {
      const day = new Date(startDate).getDay();
      if (day === 0 || day === 6) {
        document.getElementById("nextButton").disabled = true;
        setError("Bitte wählen Sie ein Datum zwischen Montag und Freitag");
      } 
      else {
        document.getElementById("nextButton").disabled = false;
        setError(null);
        let test = availability?.filter((v) => v.dayofweek_id === day);
        setSelectedDay(test[0]);
        var startDateTime = moment(startDate + ' ' + test[0].start_time, 'YYYY-MM-DD HH:mm:ss');
        var endDateTime = moment(startDate + ' ' + test[0].end_time, 'YYYY-MM-DD HH:mm:ss');
        setCheckin(startDateTime);
        setCheckout(endDateTime);
        // start fetch data after day is selected
        fetchData().catch(console.error);
      }
    }
    //setTime('10:00');
    setTimeout(function () {
        setIsLoading(false);
    }, 2000);
  }, [startDate]);

  function getCapacity()
  {
    //TODO load from Server
    var capacity = 2;
    if(id == "581" || id == "617")
    {
      capacity = 4;
    }
    if(id == "767")
    {
      capacity = 6;
    }
    if(id == "768")
    {
      capacity = 3;
    }
    return capacity;
  }

  function checkBookingPosible(bookings)
  {
    var capacity = getCapacity();
    
    var fullDay = capacity;
    var morning = capacity;
    var afternoon = capacity;

    var afternoonCounter = 0;
    var morningCounter = 0;

    for (var i = 0; i < bookings.length; i++) 
    {
      var result = bookings[i];
      
    
      var localCheckInTime = new Date(result.date_checkin);
      var localCheckOutTime = new Date(result.date_checkout);
      
      // is a booking available for selected date?
      if(new Date(startDate).getDate() == localCheckInTime.getDate())
      {
          if (localCheckInTime.getUTCHours()  >= 13)
          {
            //afternoon booking
            afternoon--;
            afternoonCounter++;
            var daysBlocked = 0;

            if (afternoonCounter == morningCounter)
            {
              daysBlocked = afternoonCounter; // or morning
            }
            else if (afternoonCounter > morningCounter)
            {
              daysBlocked = afternoonCounter;
            }
            else
            {
              daysBlocked = morningCounter;
            }

            fullDay = capacity - daysBlocked;
          }

          if (localCheckInTime.getUTCHours() < 13 && localCheckOutTime.getUTCHours()  > 13)
          {
            //fullday booking
            fullDay--;
            afternoon--;
            morning--;

            afternoonCounter++;
            morningCounter++;
          }

          if (localCheckOutTime.getUTCHours() == 13)
          {
            //moring booking
            morning--;
            morningCounter++;

            var daysBlocked = 0;
            if (afternoonCounter == morningCounter)
            {
                daysBlocked = afternoonCounter; // or morning
            }
            else if (afternoonCounter > morningCounter)
            {
                daysBlocked = afternoonCounter;
            }
            else
            {
                daysBlocked = morningCounter;
            }

            fullDay = capacity - daysBlocked;
          }
        
          updateDropDownSelection(fullDay, morning, afternoon);

          if (fullDay <= 0 && afternoon <= 0 && morning <= 0)
          {
            setError("An diesem Tag ist kein Arebitsplatz mehr verfügbar. Bitte wähle einen anderen Tag im Kalender." );
            document.getElementById("nextButton").disabled = true;
          }else
          {
            setError(null);
            document.getElementById("nextButton").disabled = false;
          }
      }
    }
    updateDropDownSelection(fullDay, morning, afternoon);
  }


  function updateDropDownSelection(fullDay, morning, afternoon)
  {
    fullDayFree = fullDay > 0;
    morningFree = morning > 0;
    afternoonFree = afternoon > 0;

    if(!fullDayFree )
    {
      document.getElementById("option1").disabled = true;
      
    }
    else
    {
      document.getElementById("option1").disabled = false;
      document.getElementById("option1").selected = true;
      checkFullDay();
    }
      if(!morningFree )
      {
        document.getElementById("option2").disabled = true;
      }
      else
      {
        document.getElementById("option2").disabled = false;
        if(!fullDayFree)
        {
          document.getElementById("option2").selected = true;
          checkMorning();
        }
         
      }
      if(!afternoonFree )
      document.getElementById("option3").disabled = true;
      else
      {
        document.getElementById("option3").disabled = false;
        if(!fullDayFree && !morningFree)
        {
          document.getElementById("option3").selected = true;
          checkAfternoon();
        }
          
      }
  }

  function checkFullDay()
  {
    var getElement = availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0];
    var startDateTime = moment(startDate + ' ' + getElement?.start_time, 'YYYY-MM-DD HH:mm:ss');
    var endDateTime = moment(startDate + ' ' + getElement?.end_time, 'YYYY-MM-DD HH:mm:ss');
    setCheckin(startDateTime);
    setCheckout(endDateTime);
    console.log('CHECK1: ',checkin,checkout);
  }

  function checkMorning()
  {
    var getElement = availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0];
    var startDateTime = moment(startDate + ' ' + getElement?.start_time, 'YYYY-MM-DD HH:mm:ss');
    var endDateTime = moment(startDate + ' ' + '13:00:00', 'YYYY-MM-DD HH:mm:ss');
    setCheckin(startDateTime);
    setCheckout(endDateTime);
    console.log('CHECK2: ',checkin,checkout);
  }

  function checkAfternoon()
  {
    var getElement = availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0];
    var startDateTime = moment(startDate + ' ' + '13:00:00', 'YYYY-MM-DD HH:mm:ss');
    var endDateTime = moment(startDate + ' ' + getElement?.end_time, 'YYYY-MM-DD HH:mm:ss');
    setCheckin(startDateTime);
    setCheckout(endDateTime);
    console.log('CHECK3: ',checkin,checkout);
  }

  function onChangeSelectTimeRage(e){
    setSelectedValue(e);
    console.log(e);
    try{
      if(e == 1){
        // full day
       checkFullDay();
      } else if(e == 2){
        // Only half day -> morning 08:00-13:00
       checkMorning();
      } else if(e == 3){
        // Only afternoon -> morning 13:00-18:00
       checkAfternoon();
      }
    }catch (e) {
      console.log(e);
    }
    /*

    */
  }

  console.log("spaceDetail", spaceDetail, availability, images);
  if (isLoading) {
    return <div className="App">Loading...</div>;
  } else {
    console.log('TestImages:', imagesTmp);
   
    return (
        <div className={` ${nameClass}`}>
          <CardMetronic className="container p-0">
            <form onSubmit={handleSubmit}>
              <CardHeaderMetronic>
                <div className="pt-10 pb-15 px-0 mx-auto col-12 col-md-10 col-lg-9 col-xxl-8">
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="d-flex align-items-center mt-8">
                      <div>
                        <div
                            className="h-65px w-65px bg-success-o-35 rounded-lg d-flex justify-content-center align-items-center text-success font-weight-boldest h3 mb-0">
                          1
                        </div>
                      </div>
                      <div className="mx-4">
                        <h2 className="font-weight-boldest"><FormattedMessage id="CONTENT.GENERAL.BOOKING"/></h2>
                        <h5 className="mb-0 text-dark-25"><FormattedMessage id="CONTENT.GENERAL.COWORKINGDETAILS"/></h5>
                      </div>
                    </div>

                    <div className="mt-8 d-flex align-items-center">
                      <div className="text-dark-25">
                        <i className="fa fa-2x fa-arrow-right"/>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mt-8">
                      <div>
                        <div
                            className="h-65px w-65px bg-dark-o-30 text-dark-65 rounded-lg d-flex justify-content-center align-items-center font-weight-boldest h3 mb-0">
                          2
                        </div>
                      </div>
                      <div className="mx-4">
                        <h2 className="font-weight-boldest"><FormattedMessage id="CONTENT.GENERAL.TITLE_STEP2"/></h2>
                        <h5 className="mb-0 text-dark-25"><FormattedMessage id="CONTENT.GENERAL.BOOKING_SUBLINE"/></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeaderMetronic>
              <CardBodyMetronic>
                <div>
                  <ul className="nav flex-column" style={{gap: "1.4rem"}}>
                    <li>
                      <div>
                        <div className="co-work-heading">
                          <div className="font-weight-bold text-dark-50 mb-4">
                            Coworking space
                          </div>
                          <h1 className="mb-4 font-weight-boldest">
                            {spaceDetail?.title}
                          </h1>
                          <h3 className="font-weight-boldest"><FormattedMessage id="FORM.GENERAL.PREVIEW"/></h3>
                        </div>
                        <div className="carousel-container pb-5">
                          <Carousel
                              key="carousel-space-image-gallery"
                          >
                            {images?.map(elem => (
                                <Carousel.Item key={elem.id}>
                                  <div className="co-work-banner h-350px">
                                    <img
                                        className="d-block h-100 w-100 object-cover"
                                        src={elem.imageUrl}
                                        alt={elem.imageUrl}
                                        width="1200"
                                        height="1200"
                                    />
                                  </div>
                                </Carousel.Item>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="px-0 col-12 col-md-8 col-lg-8">
                        <h3 className="mb-5 font-weight-boldest">
                          <FormattedMessage id="FORM.BOOKING.CHECKIN"/>
                        </h3>
                        <div className="d-flex align-items-baseline mb-4">
                          <label className="mb-0 font-weight-bold text-dark-50 mr-4">
                            <FormattedMessage id="FORM.BOOKING.CHECKIN.DATE"/>
                          </label>
                          <input
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                              type="date"
                              required
                              min={disablePastDate()}
                              className="form-control"
                          />
                        </div>
                        {error !== null && (
                            <span className="text-danger">{error} </span>
                        )}
                        <div className="d-flex align-items-baseline mb-4">
                          <label className="mb-0 font-weight-bold text-dark-50 mr-4">
                            <FormattedMessage id="FORM.BOOKING.CHECKIN.TIME"/>
                          </label>
                          <select className="form-control" disabled={error !== null || selectedDay === null}
                                 name="appt-time" onChange={(e) => onChangeSelectTimeRage(e.target.value)} value={selectedValue} >
                                  
                              <option value="1" selected id="option1">
                                {
                                  error !== null || selectedDay === null ?
                                      '':
                                  moment(availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0]?.start_time,"HH:mm:ss", true).format("HH:mm")
                                }-{
                                  error !== null || selectedDay === null ?
                                      '':
                                  moment(availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0]?.end_time,"HH:mm:ss", true).format("HH:mm")
                                }{
                                  error !== null || selectedDay === null ?
                                      '' :
                                  formatMessage({ id: "SELECT.TIME.WHOLEDAY"})
                                }
                              </option>
                              <option value="2" id="option2">
                                {
                                  error !== null || selectedDay === null ?
                                      '' :
                                      moment(availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0]?.start_time, "HH:mm:ss", true).format("HH:mm")
                                }-{
                                error !== null || selectedDay === null ?
                                    '' :
                                    formatMessage({ id: "SELECT.TIME.MORNINGDAY"})
                              }
                              </option>
                              <option value="3" id="option3">
                                {
                                  error !== null || selectedDay === null ?
                                      '': '13:00-'
                                }
                                {
                                  error !== null || selectedDay === null ?
                                      '':
                                      moment(availability?.filter((v) => v.dayofweek_id == moment(startDate)?.weekday())[0]?.end_time,"HH:mm:ss", true).format("HH:mm")
                                }{
                                  error !== null || selectedDay === null ?
                                      '' :
                                      formatMessage({ id: "SELECT.TIME.AFTERNOONDAY"})
                                }</option>
                          </select>

                          {/*
                          <input
                              disabled={error !== null || selectedDay === null}
                              onChange={(e) => setTime(e.target.value)}
                              className="form-control"
                              type="time"
                              value="10:00"
                              name="appt-time"
                              min={selectedDay?.start_time}
                              max={selectedDay?.end_time}
                              required
                              pattern="[0-9]{2}:[0-9]{2}"
                              hidden
                          />
                            */}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h3 className="mb-5 font-weight-boldest"><FormattedMessage id="FORM.OPENING.HOURS"/></h3>
                        <ul
                            className="nav flex-column pl-5"
                            style={{gap: "1rem"}}
                        >
                          {availability?.map((item) => (
                              <li key={item.id}>
                                <div className="d-flex h6">
                                  <b className="line-height-sm w-100px text-truncate text-capitalize mr-3">
                                    {item?.titlede}
                                  </b>
                                  <span className="text-dark-50">
                                    {moment(item?.start_time,"HH:mm:ss").format("HH:mm")} - {moment(item?.end_time,"HH:mm:ss").format("HH:mm")}
                              </span>
                                </div>
                              </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h3 className="mb-5 font-weight-boldest"><FormattedMessage id="FORM.LOCATION"/></h3>
                        <div className="pl-5">
                          <div className="">
                            <MapContainer
                                lat={spaceDetail?.lat}
                                lng={spaceDetail?.long}
                            />
                          </div>
                          <div className="mt-4 px-0 col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <h6 className="mb-0 text-capitalize font-weight-bold">
                              {spaceDetail?.addressline1},{" "}
                              {spaceDetail?.addressline1}
                              <br/> {spaceDetail?.city}, {spaceDetail?.country}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </CardBodyMetronic>
              <div className="card-footer">
                <div className="d-flex justify-content-between">
                  <Link
                      to="/overview"
                      className="btn btn-lg text-success bg-success-o-35 hover-opacity-79 text-capitalize"
                  >
                    <FormattedMessage id="FORM.GENERAL.BACK_BUTTON"/>
                  </Link>
                  {/* {startDate === null && endDate === null ? (
                  <button className="btn btn-lg btn-success hover-opacity-79 text-capitalize">
                    Select booking dates
                  </button>
                ) : ( */}
                  <button
                  id="nextButton"
                      type="submit"
                      // value="Submit form"
                      className="btn btn-lg btn-success hover-opacity-79 text-capitalize"
                  >
                    <FormattedMessage id="FORM.GENERAL.NEXT_BUTTON"/>
                  </button>
                </div>
              </div>
            </form>
          </CardMetronic>
        </div>
    );
  }
};

export default injectIntl(SelectedSpace);
